import clsx from "clsx";

export const navItem = clsx(
    "text-xs uppercase z-30 w-auto font-bold tracking-wide flex flex-col relative",
);
export const nestedNavItem = clsx(
    navItem + " p-2 px-8 first:pt-6 last:pb-6 hover:text-green",
);
export const nestedRouteContainer = clsx(
    "mt-4 absolute bg-white w-[250px] shadow-md z-30 rounded-lg ",
);
export const nestedItemDivider = clsx(
    "h-[2px] my-2 bg-[black]/20 w-[85%] ml-4",
);
export const nestedOpen = clsx(
    "transition-all duration-500 ease-in-out overflow-hidden",
);
export const nestedClose = clsx("transition-all duration-500 ease-in-out hidden");
