import { default as FastMarquee } from "react-fast-marquee";

export default function Marquee({ children, speed = 100 }) {
    return (
        <FastMarquee
            className="w-screen overflow-hidden"
            speed={speed}
            loop={0} // infinite loop
            direction="left" // scrolling right to left
        >
            {children}
        </FastMarquee>
    );
}
