import { routesFlat } from "@routes";

export default function getStartingOpacity(route) {
    let startingOpacity = 0.5;
    const lowerOpacity =
        route === routesFlat.landing ||
        route === routesFlat.activityData ||
        route === routesFlat.treeLevel ||
        route === routesFlat.landCarbon;

    if (lowerOpacity) {
        startingOpacity = 0.2;
    }

   return startingOpacity;
}
