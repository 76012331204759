import { SwiperSlide } from "swiper/react";
import CarouselWrapper from "../Wrapper";
import { GreenCard } from "@components";

export default function CarouselCards({ cards }) {
    return (
        <CarouselWrapper
            style={{
                "--swiper-theme-color": "#46CE84",
                width: "100%",
                marginBottom: 100,
            }}
            slidesPerView={4}
            breakpoints={{
                200: {
                    slidesPerView: 1, // mobile screens
                },
                730: {
                    slidesPerView: 2, // tablet screens
                },
                1200: {
                    slidesPerView: 3, // large screens
                },
                1600: {
                    slidesPerView: 4, // x-large screens
                },
            }}
        >
            {cards.map((card, i) => {
                return (
                    <SwiperSlide
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        key={i}
                    >
                        <GreenCard {...card} key={card.title} />
                    </SwiperSlide>
                );
            })}
        </CarouselWrapper>
    );
}
