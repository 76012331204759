import { clsx } from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { DropdownArrowIcon } from "@assets/icons";
import { DropdownMenu } from "@components";
import { routesFlat } from "@routes";
import { navItem as navItemStyle } from "@components/DropdownMenu/styles";

export default function NavItem({ route }) {
    const [isOpen, setDropdownOpen] = useState(false);
    const closeDropdown = () => setDropdownOpen(false);
    const openDropdown = () => setDropdownOpen(true);

    const dropdownProps = {
        isOpen,
        className: "h-[10px] ml-4",
    };

    const menuProps = {
        closeDropdown,
        isOpen,
        items: route.nested,
    };

    const donateStyles = route.path === routesFlat.donate && donateBtnStyle;

    return (
        <div
            className="flex flex-col"
            onMouseEnter={openDropdown}
            onMouseLeave={closeDropdown}
            onClick={() => setDropdownOpen(!isOpen)}
        >
            <Link key={route.display} to={route.path} className={navItemStyle}>
                <div
                    className={clsx(
                        "flex items-center tracking-wider " + donateStyles,
                    )}
                >
                    {route.display}
                    {route.nested && <DropdownArrowIcon {...dropdownProps} />}
                </div>
            </Link>
            <DropdownMenu {...menuProps} />
        </div>
    );
}

const donateBtnStyle = clsx(
    " border-2 px-3 py-[6px] rounded-md hover:scale-[102%]",
);
