import {
    TargetsAndGrid,
    RangeLineChart,
    StackedLineBarChart,
} from "@assets/beats/solutions";

export const metaDataHeader = { title: "", desc: "", img: "" };

export const latest = [
    {
        title: "CTrees presents on alerts for jurisdictional monitoring at AGU24",
        date: "12.11.24",
        link: "/news/AGU24",
    },
    {
        title: "CTrees hosts panel on jurisdictional finance at Climate Week NYC",
        date: "9.24.24",
        link: "/news/nycw2024-ctreespanel",
    },
    {
        title: "CTrees selected as data provider for Verra REDD methodology",
        date: "8.17.23",
        link: "/news/ctrees-verra-redd-data",
    },
];

export const hero = {
    title: "Carbon Markets",
    subheader:
        "Scientific data and insights to accelerate private investment in natural climate solutions",
    bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/carbon-markets.png",
    bgImgStyle: "h-screen object-cover object-left",
    bgImgPosition: {
        mobile: "object-left object-[12%]",
        tablet: "object-left object-[-250px]",
        desktop: "object-left object-[-20px]",
    },
};

const chartContainerStyle = "h-4/5 w-4/5 m-auto md:w-3/5";
export const firstBeat = {
    title: "Ensuring high-integrity carbon markets",
    subheader:
        "<p>Scientific data is needed to verify whether nature-based carbon credits are delivering real and additional emission reductions.</p>",
    img: <RangeLineChart className={chartContainerStyle} />,
};
export const secondBeat = {
    title: "Providing independent and science-based data",
    subheader:
        "<p>Backed by peer-reviewed science, CTrees provides geospatial data on historical carbon and land-use activities to assess the feasibility and impact of any nature project.</p>",
    img: <TargetsAndGrid />,
    bg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/beats/carbon-markets.png",
    textRight: true,
};
export const thirdBeat = {
    title: "Alignment with methodologies",
    subheader:
        "<p>CTrees data can be integrated in MRV systems for credits issued under major carbon market standards – including Verra, Architecture for REDD+ Transactions (ART), and Climate Action Reserve (CAR).</p>",
    img: <StackedLineBarChart className={chartContainerStyle} />,
};

export const dataCards = [
    {
        img: "https://ctrees-website.s3.us-west-2.amazonaws.com/news/23_08+Verra+consolidated+REDD.jpg",
        imgAlt: "",
        title: "Data for Verra REDD+ Methodology",
        subheader:
            "Since 2023, CTrees has provided jurisdictional data to Verra for its consolidated REDD+ methodology (VM0048).",
        link: "/news/ctrees-verra-redd-data",
        linkDisplayText: "learn more",
    },
    {
        img: "https://ctrees-website.s3.us-west-2.amazonaws.com/news/23_11+LUCA+update+Brazilian+Amazon.png",
        imgAlt: "",
        title: "Land Use Change Alerts for Projects",
        subheader:
            "In Nov. 2023, CTrees added a feature to analyze alerts for any project boundary.",
        link: "/news/LUCA-update-Nov-2023",
        linkDisplayText: "learn more",
    },
    {
        img: "https://ctrees-website.s3.us-west-2.amazonaws.com/news/24_11+JMRV+Platform+CA+Screenshot.png",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader:
            "In Nov. 2024, CTrees updated its data on carbon stocks, emissions, and removals for all jurisdictions globally.",
        link: "/jmrv",
        linkDisplayText: "access data",
    },
];

export const dataSubheader =
    "Access CTrees data and insights for carbon markets MRV";
