import clsx from "clsx";

export default function Gradient({
    colors,
    labelValues,
    horizontal = true,
    length = "200px",
    label,
    labelPosition = "above",
}) {
    const colorString = colors.join(",");
    const directionStyles = clsx(`rounded-sm h-5 w-[${length}]`);
    const labelAbove = labelPosition === "above";
    const Label = () => (
        <div
            className={clsx(
                " text-xxs italic" + (labelAbove ? " mb-1" : " mt-1"),
            )}
        >
            {label}
        </div>
    );
    return (
        <div
            className={clsx(
                "flex flex-col items-center" +
                    (horizontal ? "" : " -rotate-90"),
            )}
        >
            {labelAbove && <Label />}
            <div className={clsx(container)}>
                <div className={clsx(labelStyles + " mr-1")}>
                    {labelValues.min}
                </div>
                <div
                    className={directionStyles}
                    style={{
                        background: `linear-gradient(to right,${colorString})`,
                    }}
                ></div>
                <div className={clsx(labelStyles + " ml-1")}>
                    {labelValues.max}
                </div>
            </div>
            {!labelAbove && <Label />}
        </div>
    );
}

const container = clsx("flex items-center justify-center w-full ");
const labelStyles = clsx("text-xs ");

// PROPS:
// [colors] must be an array of string hex values
// [labelValues] should be written as an object with a min and max value
// [horizontal] can either be 'true' (default) or 'false'
// OPTIONAL: [length] determines how wide or tall the gradient container is
// OPTIONAL: [label] is a string displayed below the gradient
// OPTIONAL: [labelPosition] defaults to above. If anything else, displays below gradient.
