import { UnderlinedSectionHeader, ImgCarousel } from "@components";
import { teamImages } from "./constants";
import clsx from "clsx";

export default function WhoWeAre() {
    return (
        <>
            <UnderlinedSectionHeader>Who We Are</UnderlinedSectionHeader>
            <ImgCarousel images={teamImages} className="mt-10" />
            <div className={textContainerStyle}>
                CTrees is a U.S.-based science and technology 501(c)(3)
                nonprofit organization that has developed the first global
                system to measure, report, and verify (MRV) carbon stocks and
                land-use activities for every ecosystem on land. Our
                organization brings scientific data and insights to policy and
                markets, ensuring projects of any size and jurisdictional
                programs deliver carbon reductions that are both real and
                additional.
                <div className="pt-4">
                    Founded in 2022, CTrees is led by Sassan Saatchi, a senior
                    research scientist at NASA’s Jet Propulsion
                    Laboratory/Caltech and adjunct professor at UCLA’s Institute
                    of the Environment and Sustainability for Tropical Research.
                    CTrees’ team of scientific leaders, engineers, and policy
                    experts have more than two decades of experience in building
                    global carbon and biodiversity monitoring systems using
                    satellite data.
                </div>
                <div className="pt-4">
                    With expertise in remote sensing technology, artificial
                    intelligence, ecology, biology, field science, and policy,
                    CTrees and its global network of partners are pioneering
                    science and data innovations to scale nature-based climate
                    solutions, and support the sustainable restoration of
                    ecosystems worldwide.
                </div>
            </div>
        </>
    );
}

const textContainerStyle = clsx(
    "text-xl max-w-[800px] pt-10 p-4",
    "mb-20 lg:mb-40 text-center md:text-left",
);
