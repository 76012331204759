import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { navRoutes, routesFlat } from "@routes";
import { NavItem } from "./components";
import { Logout } from "../../Login/components";
import { BlackLogo } from "@assets/icons";
import { getBgColor, withScrollLogic } from "../helpers";

function NavDesktop(props) {
    const { isAuthenticated } = useAuth0();
    const currentRoute = useLocation();
    const bgColor = getBgColor(currentRoute.pathname, props.scrollOpacity);

    return (
        <div className={parentWrapperStyle} style={bgColor}>
            <Link to={routesFlat.landing}>
                <BlackLogo withText={true} className={logoStyle} />
            </Link>
            <div className={routesContainerStyle}>
                {navRoutes.map((route, i) => (
                    <NavItem route={route} index={i} />
                ))}
                {isAuthenticated && <Logout />}
            </div>
        </div>
    );
}

const parentWrapperStyle = clsx(
    "fixed top-0 z-50 hidden lg:flex w-full justify-between transition-all duration-300",
);
const logoStyle = clsx("h-[40px] w-auto self-center md:h-[60px]");
const routesContainerStyle = clsx(
    "flex justify-around w-1/2 pr-6 items-center",
);

export default withScrollLogic(NavDesktop);
