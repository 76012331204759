import { useEffect, useState } from "react";
import OpacityWrapper from "../OpacityWrapper";
import { signUp, validateEmail } from "@helpers";
import { Button } from "../Button";
import * as styles from "./styles";

const btn = {
    primary: false,
    className: "w-[140px]",
};

export default function StayConnected() {
    const [email, setEmail] = useState("");
    const [enabled, setEnabled] = useState(false);
    const [displayMsg, setDisplayMsg] = useState();
    const [checkbox, setCheckbox] = useState(false);

    const onSetEmail = (e) => {
        const input = e.target.value;
        const valid = validateEmail(input);

        setEmail(input);

        if (valid && checkbox) {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    };

    const onCheckbox = () => {
        const valid = validateEmail(email);
        if (valid && !checkbox) {
            setEnabled(true);
        }

        if (checkbox) {
            setEnabled(false);
        }

        setCheckbox(!checkbox);
    };

    const onClick = (e) => signUp(e, email, setDisplayMsg);

    useEffect(() => {
        if (displayMsg) {
            setTimeout(() => {
                setEmail("");
                setDisplayMsg(null);
            }, 7000);
        }
    }, [displayMsg]);

    const btnProps = {
        ...btn,
        onClick,
        enabled,
        className: "max-w-[140px] bg-black text-white p-8",
    };

    return (
        <OpacityWrapper className="relative mb-20 lg:mb-40">
            <div className={styles.bg}>
                <div className={styles.innerTextParentContainer}>
                    {/* STAY CONNECTED TEXT */}
                    <div className="mr-4 max-w-[500px]">
                        <div className="text-[24px] uppercase tracking-wider">
                            Stay Connected
                        </div>
                        <div className="tracking-1 mt-8 text-[18px] text-xl">
                            Subscribe to our monthly newsletter and be the first
                            to know about new data releases, research, and
                            events.
                        </div>
                    </div>
                    {/* SIGN UP INPUT & CTA */}
                    <div className="-mt-8 flex max-w-[450px] flex-col">
                        <div className={styles.emailInputContainer}>
                            <div className={styles.successOrFailMsgContainer}>
                                {displayMsg && (
                                    <>
                                        <div className={styles.displayMsg}>
                                            {displayMsg}
                                        </div>
                                        <div
                                            className={styles.displayMsgPointer}
                                        ></div>
                                    </>
                                )}
                            </div>
                            <input
                                required
                                type="email"
                                name="EMAIL"
                                id="mce-EMAIL"
                                className={styles.input + " required email"}
                                placeholder="Email"
                                value={email}
                                onChange={onSetEmail}
                            />
                        </div>
                        <div className={styles.subscribeContainer}>
                            <input
                                required
                                type="checkbox"
                                checked={checkbox}
                                onChange={onCheckbox}
                                className="mr-2"
                            />
                            <div className="-mt-[5px]">
                                By submitting your email address, you agree to
                                receive communications from CTrees. You can
                                opt-out at any time.
                            </div>
                        </div>

                        <div className=" mx-auto">
                            <Button {...btnProps}>submit</Button>
                        </div>
                    </div>
                </div>
            </div>
        </OpacityWrapper>
    );
}
