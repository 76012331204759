import { SwiperSlide } from "swiper/react";
import Lottie from "react-lottie";
import { defaultOptions } from "@assets/lottie";
import * as components from "@components";
import * as styles from "./styles";
import { beats } from "./constants";
import { ChartTextDesktop, ChartTextMobile } from "./components";

const withAnimationOptions = (Component) => (props) => {
    const options = {
        ...defaultOptions,
        animationData: props.animationData,
    };
    return <Component {...props} options={options} />;
};

const DesktopWithAnimation = withAnimationOptions(ChartTextDesktop);
const MobileWithAnimation = withAnimationOptions(ChartTextMobile);

export default function WhyCTrees() {
    return (
        <>
            <components.UnderlinedSectionHeader>
                why ctrees?
            </components.UnderlinedSectionHeader>
            <div className="hidden flex-col lg:flex">
                {beats.map((props, i) => (
                    <DesktopWithAnimation key={i} {...props} i={i} />
                ))}
                <div className="flex w-full justify-center">
                    <div className={styles.blackDot}></div>
                </div>
            </div>
            <div className="flex w-[90%] flex-col lg:hidden">
                <components.CarouselWrapper spaceBetween={20} loop={true}>
                    {beats.map((props, i) => (
                        <SwiperSlide className="h-[680px]" key={i}>
                            <MobileWithAnimation key={i} {...props} i={i} />
                        </SwiperSlide>
                    ))}
                </components.CarouselWrapper>
            </div>
        </>
    );
}

export function Chart({ options, chartDesc, chartWidth = 600 }) {
    return (
        <div className={styles.chartContainer}>
            <Lottie options={options} height={"auto"} width={chartWidth} />
            <div className={styles.chartDesc}>{chartDesc}</div>
        </div>
    );
}
