import clsx from "clsx";
import * as styles from "./styles";

export default function Hero({ props }) {
    const bgColor = ` bg-${props.bgColor}`;
    return (
        <>
            <div className={styles.parentContainer}>
                <div className={clsx(styles.header + bgColor)}></div>
                <div className={clsx(styles.rightSide + bgColor)}>
                    <div className="text-center md:text-left">
                        <div className={styles.title}>{props.title}</div>
                        <div className={styles.subheader}>
                            {props.subheader}
                        </div>
                    </div>
                </div>

                <div className="h-2/3 w-full md:h-full md:w-1/2">
                    <img
                        className="h-full w-full object-cover"
                        src={props.headerImg}
                        loading="eager"
                    />
                </div>
            </div>

            <div className={styles.textContainer}>
                <div className="text-3xl font-bold">{props.title}</div>
                <div className={styles.desc}>{props.desc}</div>
            </div>
        </>
    );
}
