import clsx from "clsx";
import { selectedStyle, pubKey } from "..";

export default function DonationCard({
    setSelected,
    selected,
    btnId,
    monthly,
    annual,
}) {
    return (
        <div
            className={clsx(selected ? selectedStyle : unSelectedStyle)}
            onClick={setSelected}
        >
            {selected && <div className={annualStyle}>${annual}/year</div>}
            <div
                className={clsx(
                    (selected
                        ? "mb-6 mt-4 text-black "
                        : "my-8 text-black/40 ") + " text-center",
                )}
            >
                <div className="text-6xl">${monthly}</div>
                <div className="text-md">per month</div>
            </div>
            {selected && (
                <>
                    <stripe-buy-button
                        buy-button-id={btnId}
                        publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY} 
                    ></stripe-buy-button>
                    <ApprovedCreditCards />
                </>
            )}
        </div>
    );
}

function ApprovedCreditCards() {
    return (
        <>
            <div className="my-2 text-xs text-black/40">
                Supported payment methods:
            </div>
            <div className="mb-8 flex w-1/2 justify-around">
                <img
                    src={
                        stripeBaseUrl +
                        "amex-b933c9009eeaf8cfd07e789c549b8c57.svg"
                    }
                    alt="amex"
                />
                <img
                    src={
                        stripeBaseUrl +
                        "mastercard-86e9a2b929496a34918767093c470935.svg"
                    }
                    alt="mastercard"
                />
                <img
                    src={
                        stripeBaseUrl +
                        "visa-fb36094822f73d7bc581f6c0bad1c201.svg"
                    }
                    alt="visa"
                />
                <img
                    src={
                        stripeBaseUrl +
                        "link-69133f6f8d9cceaf6643b54c15305d43.svg"
                    }
                    alt="link"
                />
                <img
                    src={
                        stripeBaseUrl +
                        "cashapp-7d18c6569a64a205d8cb64c9309358b5.svg"
                    }
                    alt="cashapp"
                />
            </div>
        </>
    );
}

const stripeBaseUrl = "https://js.stripe.com/v3/fingerprinted/img/";
const cardBase = clsx(
    "rounded-xl px-4 flex flex-col items-center mx-2 my-2 lg:my-0 justify-center w-[320px] md:w-[340px]",
);
const unSelectedStyle = clsx(
    cardBase +
        " border-2 border-black/20 border-dotted cursor-pointer h-[120px] lg:h-[300px]",
);
const annualStyle = clsx(
    "w-1/3 rounded-full border-black/20 bg-black/5 p-1 text-center text-xs text-black/40 mt-8",
);
