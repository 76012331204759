import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { getScrollOpacity, getStartingOpacity } from "./";

export default function withScrollLogic(Component) {
    return function (props) {
        const currentRoute = useLocation();
        const [scrollOpacity, setScrollOpacity] = useState(0.5);

        useEffect(() => {
            const initialOp = getStartingOpacity(currentRoute.pathname);
            setScrollOpacity(initialOp);

            const handleScroll = () => {
                const nextOp = getScrollOpacity(
                    getStartingOpacity(currentRoute.pathname)
                );
                setScrollOpacity(nextOp);
            };

            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }, [currentRoute.pathname]);

        return <Component {...props} scrollOpacity={scrollOpacity} />;
    };
}