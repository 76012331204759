import clsx from "clsx";

export const container = clsx(
    "relative flex flex-col items-center justify-center mt-6",
);

export const imgWrapper = clsx(
    "relative h-[300px] w-[360px] flex flex-col items-center",
);

export const navigation = clsx(
    "-mt-16 max-w-[90%] flex transform items-center justify-between rounded-full bg-darkGray px-4 py-2",
);

export const coords = clsx(
    "z-10 -mb-10 flex w-[150px] cursor-pointer justify-around rounded-full bg-darkGray px-2 py-1 text-xxs text-white/70",
);

export const copiedContainer = clsx(
    "absolute top-0 -mt-10 flex w-full justify-center text-center text-xxs",
);