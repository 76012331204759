import clsx from "clsx";
import { MetaTagHeader, DottedDivider, FAQ } from "../../components";
import Stripe from "./components/Stripe";

const metaProps = {};
export default function Donate() {
    return (
        <>
            <MetaTagHeader {...metaProps} />
            <div className={parentStyle}>
                {/* Header */}
                <div className={heroStyle}>
                    <div className="w-1/2 px-20 text-white ">
                        <div className="mb-10 text-lg uppercase tracking-widest">
                            support ctrees
                        </div>
                        <div className="text-4xl">
                            Protect Forests. Track Carbon. Power Climate Action.
                        </div>
                    </div>
                </div>
                <DottedDivider className="mb-10 mt-20 w-1/4" />
                <div className="flex flex-col items-center md:w-[800px]">
                    <div className={textStyle}>
                        Forests are one of our planet’s most powerful solutions
                        to climate change. But to protect them, we need
                        accurate, high-quality data. CTrees provides
                        science-driven forest carbon monitoring to help
                        governments, organizations, and communities stop
                        deforestation, restore ecosystems, and accelerate
                        nature-based climate solutions.
                    </div>
                    <Stripe />
                </div>
                <DottedDivider className="my-20 w-[90%]" />
                <FAQ props={questionList} />
            </div>
        </>
    );
}

const parentStyle = clsx("h-full flex flex-col items-center mb-40");
const heroStyle = clsx("h-[600px] bg-blue flex items-center w-full");
const textStyle = clsx(
    "px-8 pb-20 text-center text-xl md:px-10 lg:px-0 lg:text-2xl",
);

// Temporary (until copy finalized)
export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Measure carbon in all forest and non-forest land globally?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
