import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function CarouselWrapper({
    style = {
        "--swiper-theme-color": "#46CE84",
        "max-width": "100%",
    },
    spaceBetween = 0,
    slidesPerView = 1,
    breakpoints,
    loop = false,
    children,
    onSlideChange,
}) {
    return (
        <Swiper
            style={style}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            breakpoints={breakpoints}
            navigation
            pagination={{ clickable: true, className: "bg-green" }}
            loop={loop}
            onSlideChange={onSlideChange}
        >
            {children}
        </Swiper>
    );
}
