import clsx from "clsx";
import { ColorfulDotsCircle, IncompleteCircle } from "@assets/beats/solutions";

export const metaDataHeader = { title: "", desc: "", img: "" };

export const latest = [
    {
        title: "At AGU24, Sassan Saatchi presents on systematic land monitoring for the global stocktake",
        date: "12.10.24",
        link: "/news/AGU24",
    },
    {
        title: "CTrees releases 2023 global data on emissions, removals, and carbon stocks",
        date: "11.15.24",
        link: "/news/jmrv-update-2024",
    },
    {
        title: "CTrees launches first global system for monitoring tropical forest degradation",
        date: "10.21.24",
        link: "/news/reddai-press-release",
    },
];

export const hero = {
    title: "Global Assessment",
    subheader:
        "The first global system for monitoring, reporting, and verifying carbon and activity on land",
    bgImg: "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/solutions/hero/global.png",
    bgImgStyle: "h-screen bg-black", // same color as background temp img to fill in the gap
    bgImgPosition: {
        mobile: "object-left-top object-[12%_10%]",
        desktop: "object-left-center object-[0%_50%]",
    },
};

const solutionImgContainer =
    "m-auto h-full w-full max-w-[360px] lg:max-w-[400px]";
export const firstBeat = {
    title: "A persistent global data challenge",
    img: (
        <div className={solutionImgContainer}>
            <IncompleteCircle />
        </div>
    ),
    subheader:
        "<p>Inconsistent country inventories and the complex nature of land-use activities have hindered efforts to assess progress towards Paris Agreement targets.</p>",
};
export const secondBeat = {
    title: "Building a complete GHG inventory for land",
    subheader:
        "<p>CTrees combines remote sensing data and AI to systematically measure carbon flux and land-use activities across all ecosystems on land, resulting in historical and geospatial estimates of carbon stocks, emissions, and removals.</p>",
    img: (
        <ColorfulDotsCircle
            className={"flex h-5/6 w-5/6 items-center justify-center"}
        />
    ),
    bg: "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/solutions/beats/global_new.jpg",
    textRight: true,
};
export const thirdBeat = {
    title: "A solution for any scale",
    subheader:
        "CTrees’ science-based data captures emission reductions at smallholder to national levels, helping governments and decisionmakers measure results of climate mitigation efforts globally.",
    img: (
        <div className={clsx(solutionImgContainer + " !max-w-[450px]")}>
            <img src="https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/solutions/beats/global-beat-3.png" />
        </div>
    ),
};

export const dataCards = [
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/jmrv.png",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/tree-level.png",
        imgAlt: "",
        title: "Tree-Level Data",
        subheader: "New data finds height of trees around the globe.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/river.png",
        imgAlt: "",
        title: "River Basin Data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Other data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
];

export const dataSubheader =
    "Access CTrees data and insights on global assessment of carbon and change on land.";
