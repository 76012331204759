import clsx from "clsx";

export const parentContainer = clsx(
    "h-iframeScreen md:h-solutionHero lg:pt-[60px] items-center flex flex-col md:flex-row w-full relative md:px-10 lg:px-0 pb-10 lg:pb-0 overflow-hidden",
);
export const textContainer = clsx(
    "gap-2 flex h-full md:w-1/2 px-8 flex-col items-center md:items-start text-center md:text-left justify-end md:justify-center text-white z-10",
);
export const title = clsx(
    "uppercase tracking-widest text-3xl font-bold md:text-6xl",
);
export const link = clsx("w-[148px] lg:w-[200px] text-xs lg:text-lg");
export const animationContainer = clsx(
    "flex h-1/2 md:h-auto w-auto m-auto min-w-[250px] lg:w-1/2 justify-center items-center mt-20 md:mt-0 md:mb-0",
);
export const subheader = clsx(
    "md:my-10 my-4 max-w-[600px] text-sm md:text-2xl font-light",
);
