import { Beat1, Beat2, Beat3 } from "@assets/lottie";

export const beats = [
    {
        title: "Traditional Methods Won't Meet the Scale of the Net-Zero Challenge",
        desc: "By bringing the best in science and innovation to natural climate solutions, we can transform net zero ambitions into reality.",
        animationData: Beat1,
        chartDesc: "PATH TO NET ZERO IN 2050",
    },
    {
        title: "GROUNDBREAKING SCIENCE TO PROTECT AND RESTORE NATURE",
        desc: "CTrees' unique use of remote sensing observations, AI, and field ecology achieves a breakthrough for policy and markets, with data that ensures the integrity and longevity of natural climate solutions.",
        animationData: Beat2,
        chartDesc: "Nature-Based Climate Solutions",
        chartLeft: true,
        chartWidth: '90%',
    },
    {
        title: "OUR DATA MEASURES AT ALL SCALES",
        desc: "CTrees' global platform accounts for the climate mitigation of single trees, forests, and ecosystems anywhere on Earth.",
        animationData: Beat3,
        chartDesc: "GLOBAL FOREST Ecosystem",
        chartWidth: '70%',
    },
];