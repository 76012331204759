import Lottie from "react-lottie";
import { Button, UnderlinedSectionHeader, DottedDivider } from "@components";
import * as lottie from "@assets/lottie";
import { routesFlat } from "@routes";
import * as styles from "./styles";

const s3Bucket =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/landing/";

export default function Products() {
    // Note: Can't move to constants because need link route reference.
    const solutions = [
        {
            title: "Global Assessment",
            subheader:
                "Access land carbon emissions and removals for regional and global policy.",
            bgImg: `${s3Bucket}global.png`,
            link: routesFlat.globalAssessment,
            animationData: lottie.SolutionEarth,
            img: "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/solutions/beats/global.png",
        },
        {
            title: "Country and State Programs",
            subheader:
                "Partner to map forest carbon and reduce emissions from deforestation in jurisdictions.",
            bgImg: `${s3Bucket}country-and-state.png`,
            link: routesFlat.countryState,
            animationData: lottie.SolutionCali,
            img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/country-and-state.png",
        },
        {
            title: "Carbon Markets",
            subheader:
                "Monitor, report, and verify (MRV) performance of nature-based carbon credits with independent and scientific data.",
            bgImg: `${s3Bucket}carbon-markets.png`,
            link: routesFlat.carbonMarkets,
            animationData: lottie.SolutionTree,
            img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/carbon-markets.png",
            imgClass: "object-left",
        },
    ];
    return (
        <>
            <UnderlinedSectionHeader className="mt-40">
                Solutions
            </UnderlinedSectionHeader>
            <div className={styles.parentContainer}>
                {solutions.map((solution) => {
                    const lottieOptions = {
                        ...lottie.defaultOptions,
                        animationData: solution.animationData,
                    };

                    return (
                        <div className={styles.solutionContainer}>
                            <div className={styles.title}>{solution.title}</div>
                            <div className={styles.lottie}>
                                <img
                                    className={styles.bgImg}
                                    src={solution.bgImg}
                                />
                                <Lottie
                                    options={lottieOptions}
                                    height={"auto"}
                                    width={300}
                                />
                            </div>

                            <div className="my-4 text-center md:w-[380px]">
                                {solution.subheader}
                            </div>
                            <Button className="px-10" to={solution.link}>
                                Learn More
                            </Button>
                            <DottedDivider className="my-10 w-full lg:hidden" />
                        </div>
                    );
                })}
            </div>
        </>
    );
}
