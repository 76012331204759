import { useEffect, useState } from "react";
import clsx from "clsx";

export default function HeroOverlay({ className, img, bgImgPosition }) {
    const [imgPosition, setImgPosition] = useState(); // some hero images need extra positioning

    useEffect(() => {
        if (!bgImgPosition) {
            return;
        }
        const updateStyle = () => {
            if (window.innerWidth >= 1024) {
                // desktops
                setImgPosition(bgImgPosition.desktop);
            } else if (window.innerWidth >= 768) {
                // tablets
                setImgPosition(bgImgPosition.tablet);
            } else {
                setImgPosition(bgImgPosition.mobile);
            }
        };

        updateStyle();
        window.addEventListener("resize", updateStyle);

        return () => {
            window.removeEventListener("resize", updateStyle);
        };
    }, []);

    return (
        <>
            <img
                src={img}
                className={clsx(className, imgStyle, imgPosition)}
                loading="eager"
            />
            <div className={overlayStyle}></div>
        </>
    );
}

const imgStyle = clsx(
    " w-full h-full object-cover absolute md:top-0 md:left-0",
);
const overlayStyle = clsx(
    imgStyle + " bg-gradient-to-l from-black/80 via-black/50 to-transparent",
);
