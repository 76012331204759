import clsx from "clsx";

export const parentContainer = clsx(
    "w-full h-screen flex flex-col relative z-20 justify-center items-center md:mb-60 mb-40",
);
export const textContainer = clsx(
    "absolute top-0 z-20 md:mt-48 mt-32 flex flex-col items-center justify-center",
);
export const headerText = clsx(
    "md:mt-4 text-center md:text-[66px] text-4xl max-w-[320px] md:max-w-[1200px] font-bold uppercase leading-none tracking-widest",
);
export const subHeaderText = clsx(
    "mt-10 mb-10 md:mb-14 text-center md:text-[26px] text-xl md:max-w-[700px] max-w-[340px] leading-[1.3]",
);
export const halfCircle = clsx(
    "via-green to-blue absolute md:h-[200vh] -ml-14 sm:-ml-28 md:ml-0",
    " mb-10 inset-0 w-[130%] md:w-full rounded-full bg-gradient-to-r from-yellow",
);
export const halfCircleWrapper = clsx(
    "relative h-full max-h-[600px] md:max-h-full w-full",
);
