import clsx from "clsx";

export const parentContainer = clsx(
    "flex w-full items-center justify-center h-[650px]",
);
export const parentMobile = clsx(
    "flex w-full items-center lg:flex-row flex-col h-full px-8",
);

export const textContainer = clsx(
    "flex lg:w-1/2 w-full items-center justify-center",
);
export const chartDesc = clsx(
    "max-w-[160px] text-center text-xs font-bold uppercase",
);
export const chartContainer = clsx(
    "flex lg:h-full lg:mt-0 sm:max-w-[400px] lg:max-w-full lg:w-1/2 flex-col items-center justify-center pointer-events-none",
);
export const divider = clsx("h-[650px] h-full w-[2px] bg-black");
export const blackDot = clsx("h-2 w-2 bg-black -mt-1 rounded-full");
