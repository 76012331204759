import clsx from "clsx";

export const photoContainer = clsx(
    "my-4 flex min-w-[150px] max-w-[150px] flex-col flex-wrap items-center text-black md:mx-6 md:w-[300px]",
);
export const img = clsx(
    "h-[100px] w-[100px] overflow-hidden rounded-full md:h-[150px] md:w-[150px] ",
);
export const bioContainer = clsx("bottom-0 mt-5 flex w-full flex-col items-center md:p-0")
export const name = clsx("flex text-center text-sm font-bold")
export const title = clsx("text-center inline-block w-full text-xs text-black/50")

export const profileContainer = clsx("mx-8 my-0 mt-8 flex flex-col first:mt-0 md:mx-10 md:my-8 md:w-[200px] md:first:my-8")
export const profileName = clsx("flex text-lg font-bold capitalize")