import { useRef, useState, useEffect } from "react";
import * as styles from "./styles";
import Gradient from "../Gradient";

const handleOnSlider = () => {
    return (
        <div className="rounded-full bg-offWhite p-0.5 shadow-inner-lg">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                height="14px"
                width="14px"
                viewBox="0 -960 960 960"
                fill="black"
            >
                <path d="M480-120 300-300l58-58 122 122 122-122 58 58-180 180ZM358-598l-58-58 180-180 180 180-58 58-122-122-122 122Z" />
            </svg>
        </div>
    );
};

export default function ImgComparisonSlider({
    imgBefore,
    imgAfter,
    imgBeforeAlt,
    imgAfterAlt,
    gradientColors,
    gradientValues,
    gradientLabel,
    caption,
}) {
    const containerRef = useRef(null);
    const [sliderPosition, setSliderPosition] = useState(50);
    const [isDragging, setIsDragging] = useState(false);

    const graidentProps = {
        colors: gradientColors,
        labelValues: gradientValues,
        label: gradientLabel,
    };

    useEffect(() => {
        const handleMove = (e) => {
            if (!isDragging || !containerRef.current) return;

            const containerRect = containerRef.current.getBoundingClientRect();
            const newX =
                e.touches?.[0]?.clientX - containerRect.left ||
                e.clientX - containerRect.left;

            const newPercentage = (newX / containerRect.width) * 100;
            setSliderPosition(Math.max(0, Math.min(100, newPercentage)));
        };

        const stopDrag = () => setIsDragging(false);

        // vanilla javascript works smoother for click and drag events
        if (isDragging) {
            window.addEventListener("mousemove", handleMove);
            window.addEventListener("mouseup", stopDrag);
            window.addEventListener("touchmove", handleMove);
            window.addEventListener("touchend", stopDrag);
        }

        return () => {
            window.removeEventListener("mousemove", handleMove);
            window.removeEventListener("mouseup", stopDrag);
            window.removeEventListener("touchmove", handleMove);
            window.removeEventListener("touchend", stopDrag);
        };
    }, [isDragging]);

    return (
        <div className="flex w-full flex-col items-center justify-center">
            <Gradient {...graidentProps} />
            <div ref={containerRef} className={styles.container}>
                {/* Before Image (Fixed) */}
                <img
                    src={imgBefore}
                    alt={imgBeforeAlt}
                    className={styles.img}
                />

                {/* After Image (Slides in) */}
                <img
                    src={imgAfter}
                    alt={imgAfterAlt}
                    className={styles.img}
                    style={{
                        clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`,
                    }}
                />

                {/* Slider Handle */}
                <div
                    className={styles.sliderHandle}
                    style={{ left: `${sliderPosition}%` }}
                    onMouseDown={() => setIsDragging(true)}
                    onTouchStart={() => setIsDragging(true)}
                >
                    <div className={styles.handleOnSlider}>
                        {handleOnSlider()}
                    </div>
                </div>
            </div>
            <div className="mt-2 max-w-[350px] text-xs">{caption}</div>
        </div>
    );
}
