import { ImgTimelapse, ImgComparisonSlider } from "@components";
import { virdisGradient } from "@/helpers/constants";

export const hero = {
    bgColor: "blue",
    headerImg:
        "https://ctrees-website.s3.amazonaws.com/webv3/products/hero/activity-data.png",
    title: "Activity Data",
    subheader:
        "See deforestation, forest degradation, and forest regeneration across the tropics",
    desc: "CTrees’ activity data provides independent scientific data on deforestation, forest degradation, and regrowth to organizations seeking to reduce deforestation and restore landscapes.",
};

const s3URI =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/";

const gradientColors = virdisGradient[0].slice(0, 4).concat("#cbe11e");

const timelapseImgs = [
    { year: "'17", src: `${s3URI}Activity+Timelapse/003_frame.png` },
    { year: "'18", src: `${s3URI}Activity+Timelapse/005_frame.png` },
    { year: "'19", src: `${s3URI}Activity+Timelapse/007_frame.png` },
    { year: "'20", src: `${s3URI}Activity+Timelapse/008_frame.png` },
    { year: "'21", src: `${s3URI}Activity+Timelapse/021_frame.png` },
    { year: "'22", src: `${s3URI}Activity+Timelapse/033_frame.png` },
    { year: "'23", src: `${s3URI}Activity+Timelapse/045_frame.png` },
];

export const features = [
    {
        title: "Precise monitoring of deforestation",
        img: (
            <ImgComparisonSlider
                imgBefore={`${s3URI}activity-data-beat-1-left.png`}
                imgBeforeAlt={"Deforestation before"}
                imgAfter={`${s3URI}activity-data-beat-1-right.png`}
                imgAfterAlt={"Deforestation after"}
                gradientColors={gradientColors}
                gradientValues={{ min: "2001", max: "2024" }}
                gradientLabel={"Annual deforestion"}
                caption="Deforestation in Acre, Brazil, with black showing stable forest (2000-present) and grey as non-forest"
            />
        ),
        list: [
            "Identify forest cover change and attribute it to deforestation.",
            "Evaluate historical maps and statistics to pinpoint areas for intervention.",
            "Establish accurate baselines for rates of deforestation based on maps and design-based sampling.",
        ],
    },

    {
        title: "Uncover forest degradation",
        img: (
            <ImgTimelapse
                images={timelapseImgs}
                coordinates={{ lat: -6.0008, lon: -55.2136 }}
                caption={
                    "Road construction for logging in Morais Almeida municipality (state of Pará, Brazil) from 2017-2023"
                }
            />
        ),
        list: [
            "Identify disturbances causing forest degradation with unparalleled accuracy and resolution.",
            "Map the extent of each degradation type such as logging, fire, and road construction.",
            "Estimate the intensity of degradation.",
        ],
    },
    {
        title: "Track forest regrowth",
        bgImg: "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/activity-data-beat-3.png",
        caption:
            "Secondary forest regrowth in a tropical rainforest in the Ecuadorian Amazon",
        list: [
            "Detect the area and age of forest regeneration due to land abandonment, restoration, and afforestation.",
            "Estimate forest regrowth potential in degraded or recovering forests.",
        ],
    },
    {
        title: "Data for policy and investments",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/activity-data-beat-4.png",
        list: [
            "Set up customized monitoring systems for bi-weekly, monthly, and annual reporting.",
            "Automatically estimate performance within custom areas or administrative boundaries.",
            "Assess performance of REDD+, zero-deforestation commodity, and landscape restoration programs. ",
        ],
    },
];

export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Measure carbon in all forest and non-forest land globally?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
