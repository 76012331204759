import { RocketIcon, NewWindowIcon } from "../../assets/icons";
import { MetaTagHeader, DottedDivider, StayConnected } from "../../components";
import { routesFlat } from "../../routes";
import ContactForm from "./components/ContactForm";
import ListItem from "./components/ListItem";
import clsx from "clsx";

const metaProps = {
    title: "Contact forest science and data experts at CTrees",
    desc: "Questions about our data or interested in potential partnership opportunities? Reach out and let us know.",
    img: "",
};

const listItems = [
    {
        title: "OFFICE",
        contact: [
            "12 S. Raymond Ave, Suite B",
            "Pasadena, CA 91105",
            "United States",
        ],
    },
    {
        title: "MEDIA INQUIRIES",
        contact: ["press@ctrees.org"],
        src: ["mailto:press@ctrees.org"],
        linkIcon: <NewWindowIcon />,
    },
];

export default function Contact() {
    const listItemsWithSocial = listItems.concat({
        title: "Socials",
        contact: ["LinkedIn", "YouTube"],
        src: [routesFlat.linkedin, routesFlat.youtube],
        linkIcon: <RocketIcon />,
    });
    
    return (
        <>
            <MetaTagHeader {...metaProps} />
            <div className={titleContainerStyle}>
                <div className={titleStyle}>
                    Let’s Talk Trees
                    <DottedDivider className="mt-6" />
                </div>
            </div>
            <div className={contactContainerStyle}>
                <div className="m-0 w-full max-w-[500px] md:mt-6 lg:w-1/2">
                    {listItemsWithSocial.map((item, index) => (
                        <ListItem key={index} {...item} />
                    ))}
                </div>
                <ContactForm />
            </div>
            <DottedDivider className="mt-10 lg:hidden" />
            <div className="my-20 lg:my-60">
                <StayConnected />
            </div>
        </>
    );
}

const titleContainerStyle = clsx(
    "mb-10 flex h-60 w-full items-end justify-start px-10 lg:px-40",
);
const titleStyle = clsx(
    "w-full text-center lg:text-start text-3xl lg:text-[64px] font-semibold uppercase tracking-[2px] md:tracking-[6px]",
);
const contactContainerStyle = clsx(
    "flex h-full w-full w-max-[1000px] flex-col-reverse px-10 lg:px-40 lg:flex-row",
);
