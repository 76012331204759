import clsx from "clsx";

export default function Text({ title, subheader, textRight, green = "" }) {
    const containerStyle = clsx(
        textParentStyle +
            green +
            (textRight ? " rounded-r-lg" : " rounded-l-lg"),
    );

    const innerContainerStyle = clsx(
        textChildStyle + (textRight ? " m-10" : " ml-10"),
    );
    return (
        <div className={containerStyle}>
            <div className={innerContainerStyle}>
                <div className="mb-10 text-xl font-bold md:text-5xl">
                    {title}
                </div>
                <div className="text-[18px] lg:text-2xl" dangerouslySetInnerHTML={{ __html: subheader }}></div>
            </div>
        </div>
    );
}

const textParentStyle = clsx(
    "flex items-center justify-center h-full max-w-full md:w-1/2 ",
);
const textChildStyle = clsx("flex max-w-[600px] flex-col justify-center p-0 lg:p-10");
