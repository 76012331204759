import clsx from "clsx";

export default function ListItem({
    title,
    contact,
    src = false,
    linkIcon = false,
}) {
    return (
        <div className={containerStyle}>
            <div class="font-bold uppercase leading-3 tracking-wider">
                {title}
            </div>
            <div className="leading-tight">
                {src ? (
                    src.map((link, i) => (
                        <a
                            href={link}
                            className="flex underline hover:text-green"
                            rel="noreferrer nofollow"
                            target="_blank"
                        >
                            {linkIcon && (
                                <span className="mr-2">{linkIcon}</span>
                            )}
                            {contact[i]}
                        </a>
                    ))
                ) : (
                    <>
                        {contact?.map((string) => (
                            <div>{string}</div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}

const containerStyle = clsx("m-auto mb-8 mr-10 flex flex-col space-y-3");
