import Lottie from "react-lottie";
import { defaultOptions } from "@assets/lottie";
import { Button, HeroOverlay } from "@components";
import * as styles from "./styles";

export default function SolutionHero({
    animationData,
    title,
    subheader,
    link,
    bgImg,
    bgImgStyle,
    bgImgPosition,
}) {
    const lottieOptions = { ...defaultOptions, animationData };

    return (
        <div className={styles.parentContainer}>
            <HeroOverlay
                img={bgImg}
                className={bgImgStyle}
                bgImgPosition={bgImgPosition}
            />
            <div className={styles.animationContainer}>
                <div className="h-full w-full lg:w-[80%]">
                    <Lottie
                        options={lottieOptions}
                        height={"100%"}
                        width={"100%"}
                    />
                </div>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.title}>{title}</div>
                <div className={styles.subheader}>{subheader}</div>
                <Button to={link} className={styles.link}>
                    Explore Data
                </Button>
            </div>
        </div>
    );
}
