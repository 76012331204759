import clsx from "clsx";
import { OpacityWrapper } from "@components";
import { partners } from "@helpers/constants";
import { UnderlinedSectionHeader, Marquee } from "@components";

// CSS Marquee scroll credit: https://jackwhiting.co.uk/posts/creating-a-marquee-with-tailwind/

export default function Partners() {
    return (
        <OpacityWrapper className={parentContainerStyle}>
            <UnderlinedSectionHeader>partners</UnderlinedSectionHeader>
            <div className="relative flex overflow-x-hidden">
                <div className="max-w-[350px] lg:hidden">
                    <Icons iconList={mobileIconList} />
                </div>
                <div className="hidden lg:block">
                    <Marquee>
                        <Icons iconList={desktopIconList} />
                    </Marquee>
                </div>
            </div>
        </OpacityWrapper>
    );
}

const Icons = ({ iconList }) => {
    return (
        <div className={iconListContainerStyle}>
            {iconList.reverse().map((icon, index) => {
                const invert = icon?.includes("taskforce") ? " invert" : "";
                return (
                    <div
                        className={`${invert} flex justify-center lg:w-auto lg:mx-10`}
                        key={`marquee-item-${index}`}
                    >
                        <img src={icon} className={iconStyle} loading="eager" />
                    </div>
                );
            })}
        </div>
    );
};

const mobileIconList = [
    partners.nature,
    partners.ioes,
    partners.lincoln,
    partners.ageos,
    partners.verra,
    partners.crosswalk,
    partners.taskforce,
    partners.wcs,
    partners.goog,
    partners.cll,
    partners.terra,
    partners.trace,
    partners.vp,
    partners.cool,
    partners.aso,
    partners.fs,
];

const desktopIconList = mobileIconList.concat(mobileIconList, mobileIconList);

const parentContainerStyle = clsx(
    "my-20 flex flex-col items-center justify-center",
);
const iconStyle = clsx("my-4 lg:my-0 mx-2 max-h-[65px] lg:max-h-[100px]");
const iconListContainerStyle = clsx(
    "mt-10 flex flex-wrap items-center justify-center lg:mt-20 lg:flex-row",
);
