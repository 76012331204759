import { ImgComparisonSlider } from "@components";
import { virdisGradient, magmaGradient } from "@/helpers/constants";

export const hero = {
    bgColor: "blue",
    headerImg:
        "https://ctrees-website.s3.amazonaws.com/webv3/products/hero/tree-level.png",
    title: "Tree-Level Data",
    subheader: "Map individual trees at local to national scales",
    desc: "CTrees' unique tree-level datasets provide information on individual trees at local to national scales, helping support the implementation of natural climate solutions globally.",
};

const s3Bucket =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/Tree+Level+Slider/";

export const features = [
    {
        title: "Trees inside and outside forests",
        bgImg: "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/products/beats/tree-level-beat-1.jpg",

        list: [
            "Map trees located both inside and outside of forests, such as in savannas, urban areas, and altered landscapes.",
            "Access accurate data on the location, crown size, height, and estimated carbon content of individual trees.",
        ],
    },
    {
        title: "Scientific insight",
        img: (
            <ImgComparisonSlider
                imgBefore={`${s3Bucket}Tree-level+slider_left.png`}
                imgBeforeAlt={"Tree level before"}
                imgAfter={`${s3Bucket}Tree-level+slider_right.png`}
                imgAfterAlt={"Tree level after"}
                gradientColors={virdisGradient}
                gradientValues={{ min: "0", max: "30" }}
                gradientLabel={"Tree Canopy Height (meters)"}
                caption="Tree canopy height mapped at 0.6m spatial resolution for a forest plantation in California"
            />
        ),
        list: [
            "View information on the number, size distribution, and spatial pattern of trees across different landscapes.",
            "Study disturbance and recovery processes, growth patterns, and ecosystem dynamics.",
        ],
    },
    {
        title: "Data for land management policy",
        img: (
            <ImgComparisonSlider
                imgBefore={`${s3Bucket}treelevel_burnscar_carousel_right.png.png`}
                imgBeforeAlt={"burn scar before"}
                imgAfter={`${s3Bucket}treelevel_burnscar_carousel_left.png.png `}
                imgAfterAlt={"burn scar after"}
                gradientColors={virdisGradient}
                gradientValues={{ min: "0", max: "30" }}
                gradientLabel={"Tree Canopy Height (meters)"}
                caption="Tree canopy height maps show damage from the 2021 Caldor Fire in California"
            />
        ),
        list: [
            "Use precise information on tree-level carbon to inform nature-based climate solutions.",
            "Identify optimal areas for wildfire fuel reduction and management with.",
        ],
    },
];

export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Map individual trees at local to national scales?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
