import { useContext } from "react";
import clsx from "clsx";
import { BlueCard, UnderlinedSectionHeader, Button } from "@components";
import { NewsContext } from "@";
import { routesFlat } from "@routes";

export default function News() {
    const { articles } = useContext(NewsContext);
    const cards = articles.slice(0, 6);

    return (
        <>
            <UnderlinedSectionHeader className="mt-20">
                News
            </UnderlinedSectionHeader>
            <div className={cardsContainer}>
                {cards.map((article) => (
                    <BlueCard key={article.title} article={article} />
                ))}
            </div>
            <Button to={routesFlat.news} className="mb-40 px-10">
                read all articles
            </Button>
        </>
    );
}

const cardsContainer = clsx(
    "mb-4 mt-10 w-full max-w-[90%] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 content-center",
);
