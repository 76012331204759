export const aboutURI = "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/about/"

export const teamImages = [
    {
        src: `${aboutURI}25_01+Daniel+GCF+Presentation.JPG`,
        desc: "Daniel Melling (center) presents at CTrees data at the 2024 GCF Task Force annual meeting in Ucayali, Peru.",
    },
    {
        src: `${aboutURI}LeBien_Gabon.jpg`,
        desc: "Le Bienfaiteur Sagang (center) leads a field workshop in Gabon in 2023.",
    },
    {
        src: `${aboutURI}AllStaff_UCLARetreat.jpg`,
        desc: "CTrees team at a staff retreat at UCLA in 2024.",
    },
    {
        src: `${aboutURI}Liz_Retreat.jpeg`,
        desc: "Liz Anderson presents at the CTrees retreat in 2024.",
    },
    // {
    //     src: `${aboutURI}SassanPresentingGCF.jpg`,
    //     desc: "CEO Sassan Saatchi (left) demonstrates CTrees at the Governors’ Climate & Forests Task Force Annual Meeting in 2023.",
    // },
    {
        src: `${aboutURI}SassanPresentingGCF.jpg`,
        desc: "Sassan Saatchi (left) presents CTrees' data at the 2023 GCF Task Force meeting in Mérida, Mexico.",
    },
    {
        src: `${aboutURI}AGEOS.jpg`,
        desc: "Experts from Gabon's space agency, AGEOS, visit CTrees in Los Angeles to advance collaboration.",
    },
    
];