import {
    LineBarChart,
    LineChart,
    TargetsAndLines,
} from "@assets/beats/solutions";

export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

export const latest = [
    {
        title: "CTrees presents on alerts for jurisdictional monitoring at AGU24",
        date: "12.11.24",
        link: "/news/AGU24",
    },
    {
        title: "2023 data on carbon stocks, emissions, and removals for all jurisdictions globally now available",
        date: "11.15.24",
        link: "/jmrv",
    },
    {
        title: "CTrees introduces first platform for measuring degradation in all tropical forest countries",
        date: "10.21.24",
        link: "/news/reddai-press-release",
    },
];

export const hero = {
    title: "Country & State Programs",
    subheader:
        "Systematic greenhouse gas inventory and environmental monitoring to support jurisdictional policy",
    bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/country-and-state.png",
};

export const firstBeat = {
    title: "Incomplete information hinders progress",
    subheader:
        "<p>Many countries lack national forest inventory and monitoring systems needed to plan for sustainable resource management and meet Paris Agreement commitments.</p>",
    img: <LineBarChart className={"h-full w-3/5"} />,
};
export const secondBeat = {
    title: "Addressing the MRV gap",
    subheader:
        "<p>CTrees’ scientific data is frequent, automatic, and accurate at fine scale, enabling decision-makers to design and implement effective natural climate solutions.</p>",
    img: <TargetsAndLines />,
    bg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/beats/country-and-state.png",
    textRight: true,
};
export const thirdBeat = {
    title: "Informing policy and driving investment",
    subheader:
        "<p>CTrees’ rapid inventory and near-real-time monitoring system offers reliable data and insights that help governments implement policies, verify impact, and secure climate finance.</p>",
    img: <LineChart className={"h-full w-3/5"} />,
};

export const dataCards = [
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/jmrv.png",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/tree-level.png",
        imgAlt: "",
        title: "Tree-Level Data",
        subheader: "New data finds height of trees around the globe.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/river.png",
        imgAlt: "",
        title: "River Basin Data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Other data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
];

export const dataSubheader =
    "Work with CTrees to develop customized datasets for MRV";
