import clsx from "clsx";
export const intro = clsx(
    "text-bold uppercase tracking-wider text-white mb-2 lg:mb-10",
);
export const textContainer = clsx(
    "absolute bottom-0 z-10 mb-20 flex max-w-[700px] flex-col px-10 lg:px-0",
);
export const explain = clsx(
    "text-2xl lg:text-6xl text-white font-bold max-full",
);
export const container = clsx(
    "mb-20 lg:mb-40 p-10 lg:p-0 lg:px-20 flex flex-col-reverse",
    " lg:flex-row h-screen w-full items-center justify-center lg:justify-between relative overflow-hidden",
);
export const aboutSvg = clsx(
    "z-10 mb-10 -mt-20 -ml-50 ",
    "lg:pt-0 lg:mt-40 lg:ml-auto lg:mb-0 max-w-[150%]",
);
