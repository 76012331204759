import clsx from "clsx";
import { motion } from "framer-motion";

export default function SliderTab({ onTabClick, oneTimePayment }) {
    const onTimeStyle =
        tabBaseStyle + (oneTimePayment ? " text-black" : " text-black/40");
    const reCurStyle =
        tabBaseStyle + (!oneTimePayment ? " text-black" : " text-black/40");
        
    return (
        <div className={tabContainer}>
            <motion.div
                className={selectedTabStyle}
                animate={{ x: oneTimePayment ? 0 : "100%" }}
                transition={{ stiffness: 300, damping: 25 }}
            />
            <div className="relative flex w-full">
                <button className={onTimeStyle} onClick={onTabClick}>
                    One-Time
                </button>
                <button className={reCurStyle} onClick={onTabClick}>
                    Recurring
                </button>
            </div>
        </div>
    );
}

const tabBaseStyle = clsx(
    "w-1/2 py-2 text-center text-sm font-bold uppercase tracking-wider ",
);
const tabContainer = clsx(
    "relative mb-10 lg:mb-20 flex w-[320px] max-w-sm justify-center rounded-full bg-white p-1 shadow-md md:w-full",
);
const selectedTabStyle = clsx(
    "absolute left-0 top-0 h-full w-1/2 rounded-full bg-green shadow-md",
);
