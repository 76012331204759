import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { DonationCard, SliderTab } from "./components";

// Stripe Keys
const btn25 = "buy_btn_1R9ABDHh1sadRbzIM3EriSUJ";
const btn50 = "buy_btn_1R9APqHh1sadRbzIHNxZW03H";
const btn100 = "buy_btn_1R9ATtHh1sadRbzI2CGo4hps";

export default function Stripe() {
    const [oneTimePayment, setOneTimePayment] = useState(false);
    const [selectedRecurring, setRecurring] = useState(2);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/buy-button.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const re25Props = {
        setSelected: () => setRecurring(1),
        selected: selectedRecurring === 1,
        btnId: btn25,
        monthly: 25,
        annual: 300,
    };

    const re50Props = {
        setSelected: () => setRecurring(2),
        selected: selectedRecurring === 2,
        btnId: btn50,
        monthly: 50,
        annual: 600,
    };

    const re100Props = {
        setSelected: () => setRecurring(3),
        selected: selectedRecurring === 3,
        btnId: btn100,
        monthly: 100,
        annual: 1200,
    };

    const sliderProps = {
        oneTimePayment,
        onTabClick: () => setOneTimePayment(!oneTimePayment),
    };
    return (
        <div className="flex w-full flex-col items-center">
            <SliderTab {...sliderProps} />
            {oneTimePayment ? (
                <div className={selectedStyle}>
                    <stripe-buy-button
                        buy-button-id="buy_btn_1R99YdHh1sadRbzIi4csM22a"
                        publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                    ></stripe-buy-button>
                </div>
            ) : (
                <div className="flex flex-col justify-around lg:flex-row">
                    {/* $25/MONTH */}
                    <DonationCard {...re25Props} />
                    {/* $50/MONTH */}
                    <DonationCard {...re50Props} />
                    {/* $100/MONTH */}
                    <DonationCard {...re100Props} />
                </div>
            )}
        </div>
    );
}

const cardBase = clsx(
    "rounded-xl px-4 flex flex-col items-center mx-2 my-2 lg:my-0 justify-center w-[320px] md:w-[340px]",
);
export const selectedStyle = clsx(
    cardBase + " bg-[#f9f9f9] pt-8 pb-4 shadow-lg h-[300px]",
);
