import clsx from "clsx";
import * as components from "@components";
import { Chart } from "../index";
import * as styles from "../styles";

export default function ChartTextDesktop(props) {
    return (
        <>
            <div className={styles.parentContainer} key={props.title}>
                {props.chartLeft && (
                    <>
                        <Chart {...props} />
                        <div className={styles.divider}></div>
                    </>
                )}
                <div className={styles.textContainer}>
                    <div className="flex max-w-[500px] flex-col">
                        <div className="mb-10 text-2xl uppercase lg:text-4xl">
                            {props.title}
                        </div>
                        <div className="lg:text-xl">{props.desc}</div>
                    </div>
                </div>
                {!props.chartLeft && (
                    <>
                        <div className={styles.divider}></div>
                        <Chart {...props} />
                    </>
                )}
            </div>
            <components.DottedDivider
                key={props.desc}
                className={clsx(props.i === 2 && "border-black ") + " w-[90%]"}
            />
        </>
    );
}
