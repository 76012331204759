import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { routesFlat } from "@routes";
import { BlackLogo } from "@assets/icons";
import { getBgColor, withScrollLogic } from "../../../helpers";
import { Toggle } from "../";

function Header(props) {
    const currentRoute = useLocation();
    const bgColor = getBgColor(
        currentRoute.pathname,
        props.scrollOpacity,
        props.isOpen,
    );

    return (
        <div className={parentContainer} style={bgColor}>
            <Link
                to={routesFlat.landing}
                className={`flex ${props.isOpen ? "fixed" : ""}`}
                onClick={props.isOpen ? props.cycleOpen : null}
            >
                <BlackLogo
                    withText={true}
                    className="ml-4 h-20 max-w-[150px]"
                />
            </Link>
            <Toggle {...props} />
        </div>
    );
}

const parentContainer = clsx(
    "fixed top-0 z-40 flex w-full min-h-[80px] justify-between lg:hidden",
);

export default withScrollLogic(Header);
