import { HeroOverlay } from "@components";
import { About } from "@assets/hero";
import { aboutURI } from "../WhoWeAre/constants";
import * as styles from "./styles.js";

export default function Hero() {
    return (
        <div className={styles.container}>
            <HeroOverlay img={`${aboutURI}bannerimage_forest.jpg`} />
            <div className={styles.textContainer}>
                <div className={styles.intro}>our mission</div>
                <div className={styles.explain}>
                    Track carbon in every living tree on Earth to accelerate
                    natural climate solutions.
                </div>
            </div>
            <About className={styles.aboutSvg} />
        </div>
    );
}
