import { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Button } from "@components";
import { routesFlat } from "@routes";
import * as lottie from "@assets/lottie";
import * as styles from "./styles";

const lineOptions = {
    ...lottie.defaultOptions,
    animationData: lottie.Line,
};

export default function Hero() {
    const [lottieHeight, setLottieHeight] = useState(380);

    useEffect(() => {
        const updateHeight = () => {
            const screenHeight = window.innerHeight;
            if (screenHeight <= 667) {
                setLottieHeight(250); // iPhone SE (or small screens)
            } else {
                setLottieHeight(380); // Default height
            }
        };

        updateHeight(); // Set height on mount
        window.addEventListener("resize", updateHeight);

        return () => window.removeEventListener("resize", updateHeight);
    }, []);

    const btnProps = {
        to: routesFlat.products,
        primary: false,
        className: "w-[200px]",
    };
    return (
        <>
            <div className={styles.parentContainer}>
                {/* HALF CIRCLE */}
                <div className={styles.halfCircleWrapper}>
                    <div
                        className={styles.halfCircle}
                        style={{
                            top: "-107vh",
                            clipPath: "ellipse(200% 50% at 50% 100%)",
                        }}
                    ></div>
                </div>
                {/* SUB HEADER TEXT */}
                <div className={styles.textContainer}>
                    <div className={styles.headerText}>
                        Track forest carbon anywhere on the planet
                    </div>
                    <div className={styles.subHeaderText}>
                        Access the first global system to measure carbon and
                        activity in every ecosystem on land, at any scale.
                    </div>
                    <Button {...btnProps}>See our data</Button>
                    <div className="mt-10">
                        <Lottie options={lineOptions} height={lottieHeight} width={50} />
                    </div>
                </div>
            </div>
        </>
    );
}
