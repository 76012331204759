import { routesFlat } from "@routes";

export default function getBgColor(
    currentRoute,
    scrollOpacity,
    isMobileOpen = false,
) {
    if (
        isMobileOpen ||
        currentRoute.includes(routesFlat.contact) ||
        currentRoute.includes(routesFlat.news)
    ) {
        {
            return { backgroundColor: `rgb(70, 206, 132)` }; // bg-green
        }
    }

    return { backgroundColor: `rgba(255, 255, 255, ${scrollOpacity})` };
}
