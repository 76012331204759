import { useState } from "react";
import { SwiperSlide } from "swiper/react";
import CarouselWrapper from "../Wrapper";
import * as styles from "./styles";

export default function ImgCarousel({ images, className }) {
    const [slideCount, setSlideCount] = useState(1);
    return (
        <div className={className + styles.container}>
            <CarouselWrapper
                spaceBetween={20}
                breakpoints={{
                    768: {
                        slidesPerView: 3, // (medium screens and up)
                    },
                }}
                onSlideChange={(e) => setSlideCount(e.realIndex + 1)}
                loop={true}
            >
                {images.map((img, i) => (
                    <SwiperSlide key={i}>
                        <div className={styles.slide}>
                            <img
                                className={styles.img}
                                src={img.src}
                                loading="lazy"
                            />
                            <div className={styles.desc}>{img.desc}</div>
                        </div>
                    </SwiperSlide>
                ))}
            </CarouselWrapper>
            <div className={styles.counter}>
                {slideCount}/{images.length}
            </div>
        </div>
    );
}
