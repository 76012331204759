import clsx from "clsx";
import * as components from "@components";
import {Chart} from '../index'
import * as styles from "../styles";

export default function ChartTextMobile(props) {
    const chartProps = {
        ...props,
        chartWidth: "100%",
    };
    return (
        <>
            <div className={styles.parentMobile} key={props.title}>
                <div className={titleStyle}>{props.title}</div>
                <components.DottedDivider
                    key={props.desc}
                    className="mb-4 w-full"
                />
                <Chart {...chartProps} />
                <components.DottedDivider
                    key={props.desc}
                    className="mb-4 mt-6 w-full"
                />
                <div>{props.desc}</div>
                <components.DottedDivider
                    key={props.desc}
                    className="mt-4 w-full"
                />
            </div>
        </>
    );
}

const titleStyle = clsx("mb-4 mt-10 text-center text-xl font-bold");
